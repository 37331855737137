import React from 'react'
import Footer from '../components/Footer'

export const Background404 = () => {
  return (

        <div>
            <div class="hello"></div>
            <Footer/>
        </div>

  )
}
